import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import {
	Product,
	SourceTrackingTypes,
	ClientFeatureOptions,
	ProductEnum,
} from '@uc/web/shared/data-models';
import { CtaClearingButtonsComponent } from './components/cta-clearing-buttons/cta-clearing-buttons.component';
import { CtaButtonsComponent } from './components/cta-buttons/cta-buttons.component';
import { CommonModule } from '@angular/common';
import { ScrollIntoViewDirective } from '../directives/scroll-into-view.directive';
import { ClientFeatureHandler } from '@uc/web/core';
import { ActivatedRoute } from '@angular/router';
@Component({
	selector: 'uc-client-profile-sticky-lead-bar-desktop',
	templateUrl: './client-profile-sticky-lead-bar-desktop.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		CtaClearingButtonsComponent,
		CtaButtonsComponent,
		ScrollIntoViewDirective,
		CommonModule,
	],
})
export class ClientProfileStickyLeadBarDesktopComponent implements OnInit {
	@Input({ required: true }) uniId!: number;
	@Input({ required: true }) uniName = '';
	@Input({ required: true }) paidFeatures!: ClientFeatureOptions[];
	@Input({ required: true }) externalUrl!: string;
	@Input({ required: true }) source!: string;
	@Input({ required: true }) sourceType: SourceTrackingTypes = '';
	@Input({ required: true }) product!: Product | null;
	@Input({ required: true }) showClearing!: boolean;
	@Input() prospectusExternalUrl!: string | undefined;
	@Input() externalEventsUrl!: string | undefined;
	@Input() phoneNumber!: string;
	@Input() privacyPolicyUrl = '';
	@Input() courseName = '';
	@Input({ required: true }) ugSearchOrderPosition!: number | null;
	@Input({ required: true }) cleSearchOrderPosition!: number | null;
	clearingEnabled!: boolean;
	componentName = 'Sticky Lead Bar';

	clientFeatures!: ClientFeatureHandler;

	nonClearingText = {
		title: 'Learn more about this university',
		description: 'Find out everything you need to know about your next home.',
	};

	clearingText = {
		title: 'Clearing vacancies are available',
		description:
			'Reserve your place now by calling the university or registering your interest',
	};

	readonly productEnum = ProductEnum;

	constructor(private _route: ActivatedRoute) {}

	ngOnInit(): void {
		const isPreview = this._route.snapshot.queryParams['preview'] === 'true';
		this.clientFeatures = new ClientFeatureHandler(this.paidFeatures, isPreview);
	}
}
