import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import {
	SourceTrackingTypes,
	FeaturedCalls,
	FeaturedRegisteredInterest,
	FeaturedClearingVisitWebsite,
} from '@uc/web/shared/data-models';
import {
	RegisterInterestBtnComponent,
	CallUniBtnComponent,
	ClearingVisitWebsiteBtnComponent,
} from '@web/shared/ui';

@Component({
	selector: 'uc-cta-clearing-buttons',
	templateUrl: './cta-clearing-buttons.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		RegisterInterestBtnComponent,
		CallUniBtnComponent,
		ClearingVisitWebsiteBtnComponent,
	],
})
export class CtaClearingButtonsComponent {
	uniId = input.required<number>();
	uniName = input.required<string>();
	componentName = input.required<string>();
	externalUrl = input.required<string>();
	phoneNumber = input.required<string>();
	source = input.required<string>();
	sourceType = input.required<SourceTrackingTypes>();
	isFeaturedCalls = input.required<FeaturedCalls>();
	isFeaturedRegisterInterest = input.required<FeaturedRegisteredInterest>();
	isFeaturedClearingVisitWebsite = input.required<FeaturedClearingVisitWebsite>();
	cleSearchOrderPosition = input.required<number>();
	privacyPolicyUrl = input.required<string>();
}
